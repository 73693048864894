import React from "react"
import supportersStyles from './supporters-a7.module.css'


export default class SupportersA7 extends React.Component {
  render() {
    const icons=[
      "//cdn.chinarun.com/upload/site/1000/img/art/20170519/20170519120428274.jpg?_t_=1495195468",
      "//cdn.chinarun.com/upload/site/1132/img/art/201907/12/ce913996-b838-4572-ab03-b371294f1e87.jpg?_t_=1562949035",
      "//cdn.chinarun.com/upload/site/1000/img/art/20160721/20160721095233009.jpg?_t_=1469094753",
      "//cdn.chinarun.com/upload/site/1132/img/art/201910/10/ad63df0e-978e-4f0f-ae25-3e58c99db190.jpg?_t_=1570701899",
      "//cdn.chinarun.com/upload/site/1132/img/art/201907/12/ce913996-b838-4572-ab03-b371294f1e87.jpg?_t_=1562949035",
      "//cdn.chinarun.com/upload/site/1000/img/art/20160721/20160721095233009.jpg?_t_=1469094753",
      "//cdn.chinarun.com/upload/site/1132/img/art/201910/10/ad63df0e-978e-4f0f-ae25-3e58c99db190.jpg?_t_=1570701899",
      "//cdn.chinarun.com/upload/site/1000/img/art/20170519/20170519120428274.jpg?_t_=1495195468",
      "//cdn.chinarun.com/upload/site/1132/img/art/201907/12/ce913996-b838-4572-ab03-b371294f1e87.jpg?_t_=1562949035",
      "//cdn.chinarun.com/upload/site/1000/img/art/20160721/20160721095233009.jpg?_t_=1469094753",
      "//cdn.chinarun.com/upload/site/1132/img/art/201910/10/ad63df0e-978e-4f0f-ae25-3e58c99db190.jpg?_t_=1570701899",
      "//cdn.chinarun.com/upload/site/1132/img/art/201907/12/ce913996-b838-4572-ab03-b371294f1e87.jpg?_t_=1562949035"]
    return(
      <div className={supportersStyles.supportersContainer}>
        <div className={supportersStyles.title}>
          <div className={supportersStyles.titleText}>赛事赞助商</div>
        </div>
        <div className={supportersStyles.iconslist}>
          {icons.map((icon,index)=><div key={index} className={supportersStyles.supporterIcon}>
            <div>
              <img src={icon} alt="赞助商"/>
            </div>

          </div>)}
        </div>
      </div>
    )
  }
}