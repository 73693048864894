import React from "react"
import newsStyles from './news-a7.module.css'


export default class NewsA7 extends React.Component {
  render() {
    const news=["新闻资讯一新闻资讯一新闻资讯一","新闻资讯二新闻资讯二","新闻资讯三新闻资讯三"]
    const newslist=news.map((news,index)=><li key={index} className={newsStyles.newsItem}>
      <div className={newsStyles.dot}></div>
      <div>{news}</div>

    </li>)

    const notices=["新闻资讯一新闻资讯一新闻资讯一","新闻资讯二新闻资讯二","新闻资讯三新闻资讯三"]
    const noticeslist=notices.map((notice,index)=><li key={index} className={newsStyles.noticeItem}>
      <div className={newsStyles.dot}></div>
      <div>{notice}</div>
    </li>)
    return(
      <div className={newsStyles.newsContainer}>
        <div className={newsStyles.news}>
          <div className={newsStyles.title}>
            <div className={newsStyles.titleText}>新闻</div>
            <div className={newsStyles.titleBg}></div>
          </div>
          <ul className={newsStyles.newslist}>
            {newslist}
          </ul>
        </div>
        <div className={newsStyles.notices}>
          <div className={newsStyles.title}>
            <div className={newsStyles.titleText}>公告</div>
            <div className={newsStyles.titleBg}></div>
          </div>
          <ul className={newsStyles.noticeslist}>
            {noticeslist}
          </ul>
        </div>
      </div>
    )
  }
}