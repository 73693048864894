import React from "react"
import LayoutA7 from "../../components/layout-a7"
import FastEntryA7 from '../../components/fast-entry-a7'
import CountDownA7 from '../../components/countdown-a7'
import NewsA7 from '../../components/news-a7'
import homeStyles from './a7_home.module.css'
import WonderfulPhotosA7 from "../../components/wonderful-photos-a7"
import SupportersA7 from "../../components/supporters-a7"
import PosterSwiperA7 from '../../components/poster-swiper-a7'

export default  class A6Home extends React.Component {
  render() {
    const posters=[
      '//cdni.cugefit.com/szmls20170312/s/ae5a4729-a6c6-478b-b2ae-409bedfcc70c_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/s/5aa17d5b-dcf4-4661-9926-e88239a4be5c_900x600.jpeg'
    ]
    const photos=['//cdni.cugefit.com/szmls20170312/s/ae5a4729-a6c6-478b-b2ae-409bedfcc70c_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/s/5aa17d5b-dcf4-4661-9926-e88239a4be5c_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/s/ed8ef306-5795-4ec4-ad65-7c405ea8c18b_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/0f901f59-365a-48cf-9bdb-745aae9784cc_900x1190.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/1a854aa1-e2f5-42f6-b27c-e94a4890d086_900x637.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/21d92fcc-5ba6-450a-8c7f-43042769f7cf_900x474.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/591f320c-cd80-4882-b8c8-22e1b9eca053_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/5d76a590-9d11-4e59-aebb-9eaaf89cf752_900x642.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/75b7f65c-e685-493a-a79d-e4796028c6dc_900x651.jpeg']
    return(
      <LayoutA7 >
        <div className={homeStyles.posters}>
          <PosterSwiperA7  imageUrls={posters}/>
        </div>
        <div className={homeStyles.fastentry}>
          <FastEntryA7 />
        </div>
        <div>
          <CountDownA7 count={1000}/>
        </div>
        <div className={homeStyles.news}>
          <NewsA7 />
        </div>
        <div className={homeStyles.centerBg}>
          <div className={homeStyles.squareLeft}>
          </div>
          <div className={homeStyles.squareRight}>
          </div>
          <div className={homeStyles.wonderfulPhotos}>
            <WonderfulPhotosA7 photos={photos}/>
          </div>
          <div className={homeStyles.supporters}>
            <SupportersA7 />
          </div>
        </div>
      </LayoutA7>
    )

  }
}