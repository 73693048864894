import React from "react";
import fastEntryStyles from './fast-entry-a7.module.css'
import MyNavLink from './my-nav-link'

export default class FastEntryA7 extends React.Component{
  render() {
    return(
      <div className={fastEntryStyles.fastEntryContainer}>
        <div className={fastEntryStyles.left}>
          <div >
            <div className={fastEntryStyles.text}>报名</div>
            <div className={fastEntryStyles.icon}>
              <img src={require('../images/a7/enroll.png')}/>
            </div>
          </div>
        </div>
        <div className={fastEntryStyles.leftPhone}>
          <div>
            <img src={require('../images/a7/enroll.png')}/>
            <div>报名</div>
            <div className={fastEntryStyles.bottom}></div>
          </div>

        </div>
        <div className={fastEntryStyles.right}>
          <div>
            <div className={fastEntryStyles.text}>参赛号</div>
            <div className={fastEntryStyles.icon}>
              <img src={require('../images/a7/num.png')}/>
            </div>
          </div>
        </div>
        <div className={fastEntryStyles.rightPhone}>
          <div>
            <img src={require('../images/a7/num.png')}/>
            <div>报名</div>
            <div className={fastEntryStyles.bottom}></div>
          </div>
        </div>
      </div>
    )
  }
}