import React from "react"
import PropTypes from "prop-types"
import Swiper from 'swiper'
import './poster-swiper-a7.css'


export default class PosterSwiperA7 extends React.Component {
  static propTypes={
    imageUrls:PropTypes.array.isRequired
  }
  componentDidMount() {
    var swiper = new Swiper('.swiper-a7', {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="'+ className +'"></div>';
        },
        navigation: {
          nextEl: '.swiper-button-next',//自动隐藏
          prevEl: '.swiper-button-prev',//自动隐藏
          hiddenClass: 'btn-hidden',//某些情况下需要隐藏前进后退按钮时，可以设定一个自定义的类名。
        }
      },
    });
  }
  render() {
    const images=this.props.imageUrls.map((image,index)=>
      <div key={index} className="swiper-slide"  style={{backgroundImage:'url('+image+')',backgroundSize:'cover',backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'}}>
      </div>)
    return(
      <div className="swiper-container swiper-a7">
        <div className="swiper-wrapper">
          {images}
        </div>
        <div className="swiper-button-prev pre-a7"></div>
        <div className="swiper-button-next next-a7"></div>
        <div className="swiper-pagination"></div>
      </div>
    )
  }
}